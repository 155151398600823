import React from "react";
import "../../assets/css/home.css";
import HeroSection from "../sections/HeroSection";
import MainSection from "../sections/MainSection";
import { Helmet } from "react-helmet";
import Banner from "../Alert/Banner";

const Home = (props) => {
  return (
    <div>
      <Helmet>
        <title>
          AI-Powered Lending Solutions | Loan Management Software - Cync
          Software®
        </title>
        <meta
          name="description"
          content="Supercharge your lending with AI automation: Boost productivity and save costs. Cloud API ensures resilience, scalability, innovation. Schedule a demo!"
        />
      </Helmet>
      <HeroSection />
      <MainSection />
      {/* Add other sections/components */}
    </div>
  );
};

export default Home;
