// HomeSection.jsx
import React from "react";
import backgroundImage from "../../assets/background/Herobanner.png";
import backgroundImage2 from "../../assets/background/finovatebanner.jpg"; // Example for the second slide
import "./HeroSection.css";
import "../../assets/css/home.css";
import Button from "../Button.jsx";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { isValidPath } from "../../utils/validation.jsx";

const handleNavigation = (url) => {
  if (isValidPath(url)) {
    window.location.assign(url);
  } else {
    console.warn("Invalid path:", url);
  }
};

const HeroSection = () => {
  return (
    <section className="section-with-carousel">
      <Carousel controls={false} interval={15000} indicators={false}>
        {/* <Carousel.Item>
          <div
            className="section-with-slidebackground"
            style={{ backgroundImage: `url(${backgroundImage2})` }}
          >
            <Container className="h-100">
              <Row className="h-100 align-items-center">
                <Col lg={12} style={{justifyContent:'center', display:'flex', top:'270px', position:'relative'}}>
                  <div className="content">
                    <a href="/conference/2024/finovate-fall" onClick={() => handleNavigation("/conference/2024/finovate-fall")}>
                      <Button size="small" color="primary">
                        Read More
                      </Button>
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Carousel.Item> */}
        <Carousel.Item>
          <div
            className="section-with-background"
            style={{ backgroundImage: `url(${backgroundImage})` }}
          >
            <Container className="h-100">
              <Row className="h-100 align-items-center">
                <Col lg={9}>
                  <div className="content">
                    <h1>
                      Supercharge Your Lending <br />
                      Stack
                    </h1>
                    <p style={{ marginTop: "10px", fontSize: "20px" }}>
                      AI-powered automation to reclaim hours in productivity and drive
                      <br />
                      substantial cost savings. Cloud-based with API-led architecture
                      to<br /> ensure high resiliency, scalability, and continuous innovation.
                    </p>
                    <br />
                    <a onClick={() => handleNavigation("/request-a-demo")}>
                      <Button size="small" color="primary">
                        Schedule a Demo
                      </Button>
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Carousel.Item>
      </Carousel>
    </section>
  );
};

export default HeroSection;
