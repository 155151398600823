import React, { useState, useEffect } from "react";
import "./AlertPopup.css"; // Import a separate CSS file for styling

const AlertPopup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("Popup is now visible"); // Debug log
      setIsVisible(true);
    }, 3000); // Show popup after 3 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    console.log("Popup closed"); // Debug log
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="product-popup fade-in">
        <div className="popup-content">
          <span className="close-btn" onClick={handleClose}>
            <i
              class="fa-solid fa-circle-xmark"
              style={{ color: "#152C5B" }}
            ></i>
          </span>
          <h4 style={{ color: "#152C5B", fontSize: "20px" }}>Just Launched!</h4>
          <p
            style={{
              fontSize: "17px",
              textDecoration: "underline",
              color: "#0D84E8",
            }}
          >
            {" "}
            <a href="/cync-syndicated-lending">
              <b>Cync Syndicated Lending</b>
            </a>
          </p>
        </div>
      </div>
    )
  );
};

export default AlertPopup;
