// Banner.js
import React from "react";

const Banner = () => {
  return (
    <div
      style={{
        backgroundColor: "#152C5B",
        color: "white",
        textAlign: "center",
        padding: "15px 0",
        fontSize: "15px",
        position: "fixed",
        width: "100%",
        top: "0",
        zIndex: "1000",
      }}
    >
      <span>
        Introducing{" "}
        <a
          href="/cync-syndicated-lending"
          style={{
            color: "white",
            textDecoration: "underline",
          }}
        >
          <b style={{ textDecoration: "underline" }}>
            {" "}
            Cync Syndicated Lending
          </b>
        </a>
        : A Highly Flexible, Automated Solution for Simplifying Syndicated Loan
        Management!
      </span>
    </div>
  );
};

export default Banner;
